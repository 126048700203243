#currency-selector-wrapper {
  display: inline;

  .dropdown {
    display: inline-block;
    width: 24%;
    margin-right: 2%;
    background: white;
    padding: 2.1rem;
    font-family: 'Italian Plate No1 Mono', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.75rem;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 0.2rem;

    @media (max-width: 991px) {
      width: 23%;
      font-size: 1.7rem;
      padding: 0.7rem;
    }
  }

  .custom-toggle__selected {
    color: black;
    display: flex;
    justify-content: space-around;
    text-decoration: none;
  }

  .dropdown-menu {
    max-width: 26rem;
    max-height: 30rem;
    overflow: auto;

    input {
      padding: 1rem;
      font-size: 1.6rem;
    }
  }

  .dropdown-item {
    font-size: 1.4rem;
  }
}
