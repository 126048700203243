section#method-description {
  background-color: #f7f7f7;

  #method-description__title {
    font-size: 6rem;
    text-align: center;
    font-family: 'Georgia', sans-serif;
    margin: 0;
    padding-top: 15rem;
    padding-bottom: 4rem;

    @media (max-width: 767px) {
      text-align: left;
      font-size: 2.5rem;
      line-height: 2.8rem;
      padding-top: 9rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 5rem;
      padding-top: 10rem;
    }
  }

  #method-description__subtitle {
    padding-bottom: 15rem;
    font-family: 'Inter Variable', sans-serif;
    font-size: 2rem;
    line-height: 2.4rem;
    max-width: 65%;
    margin: 0 auto;
    text-align: center;
    color: #707070;

    @media (max-width: 767px) {
      text-align: left;
      font-size: 1.4rem;
      line-height: 1.8rem;
      max-width: 100%;
      padding-bottom: 8rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 10rem;
      max-width: 85%;
    }
  }

  .method-description-cards {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    font-family: 'Inter Variable', sans-serif;

    @media (max-width: 991px) {
      grid-gap: 2%;
      grid-template-columns: 100%;
    }
  }

  .wrapper {
    padding-bottom: 15rem;
  }
}

.rounded-section {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}

.full-rounded-section {
  border-radius: 3rem;
}

.bg-white {
  background-color: white;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-blue {
  background-color: #0865de;
}

.btn {
  border-style: solid;
  cursor: pointer;
  margin-top: 6rem;
  width: 40%;
  background-color: #17cb00;
  border-color: #17cb00;
  border-radius: 1rem;
  color: white;
  font-family: 'Georgia', sans-serif;
  font-weight: 700;
  padding: 2.5rem 0;
  font-size: 2.2rem;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    padding: 2rem 0;
    width: 60%;
  }
}

.btn.success {
  background: transparent;
  color: #17cb00;
  border-color: #17cb00;
}

.hide {
  display: none;
}

.mobile-hide {
  @media (max-width: 767px) {
    display: none;
  }
}

.tablet-hide {
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
}

.desktop-hide {
  @media (min-width: 992px) {
    display: none;
  }
}

.center {
  text-align: center;
}
