#application-form {
  padding: 10rem 0;
  font-size: 1.8rem;

  @media (max-width: 767px) {
    padding: 7rem 0;
  }

  @extend .bg-light-gray;

  .form-title {
    font-family: "Georgia", sans-serif;
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      font-size: 3rem;
    }
  }

  .mandatory {
    color: #0865de;
    font-weight: bold;
  }

  .mandatory-label {
    @extend .mandatory;

    text-align: center;
    display: block;
    margin-bottom: 3rem;
    font-weight: normal;
  }

  .form-label {
    font-family: "Inter Variable", sans-serif;
    line-height: 3.3rem;
    color: #848484;
  }

  .form-grid {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 2%;

    @media (max-width: 767px) {
      grid-template-columns: 100%;
      grid-gap: unset;
    }
  }

  input,
  .upload-trigger {
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 1rem;
    padding: 3.75rem 2rem;
    font-size: 2rem;

    @media (max-width: 767px) {
      padding: 2.5rem 2rem;
    }
  }

  .form-group {
    margin-bottom: 4rem;

    @media (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }

  .upload-trigger {
    padding: 2rem 0;
    display: block;
    display: flex;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 767px) {
      padding: 1rem 0;
    }
  }

  .btn {
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 2rem;
      font-size: 2rem;
    }
  }

  .spinner-border {
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
  }

  span.tablet-hide.desktop-hide {
    .mandatory {
      display: block;
      margin-top: 1rem;
      font-size: 1.6rem;
    }
  }

  .form-success {
    text-align: center;
    min-height: 77.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
      min-height: unset;
    }

    #raised-hands-emoji {
      font-size: 6rem;
      line-height: 6.8rem;
      display: block;
    }

    .btn {
      background-color: #0865de;
      border-color: #0865de;
      width: 30%;

      @media (max-width: 767px) {
        width: 60%;
        margin-top: 2rem;
      }
    }

    h1 {
      font-family: "Georgia", sans-serif;
      font-size: 4rem;
      line-height: 4.6rem;
      padding: 0 13%;

      @media (max-width: 767px) {
        font-size: 3rem;
        line-height: 4rem;
        padding: 0 5%;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding: 0;
      }
    }
  }
}
