@import-normalize;

@import './styles/fonts.scss';
@import './styles/colors.scss';
@import './styles/Header.scss';
@import './styles/Footer.scss';
@import './styles/ImageWithText.scss';
@import './styles/MethodDescription.scss';
@import './styles/MethodDescriptionCard.scss';
@import './styles/JoinEnglish4Execs.scss';
@import './styles/ISACalculator.scss';
@import './styles/PrivacyPolicy.scss';
@import './styles/CookieBanner.scss';
@import './styles/CurrencySelector.scss';
@import './styles/ApplicationForm.scss';

html {
  font: 300 62.5% "Aeonik", sans-serif;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

.wrapper {
  max-width: 1440px;
  padding: 0 5vw;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 0 7vw;
  }
}

.btn {
  &:hover {
    color: white;
  }
}
