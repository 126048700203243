.join-english4execs {
  background-color: #0865de;
  text-align: center;

  .wrapper {
    padding: 15rem 5vw;

    @media (max-width: 767px) {
      padding: 9rem 5vw;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 10rem 5vw;
    }
  }

  .join-english4execs__title {
    font-size: 5rem;
    font-family: 'Georgia', sans-serif;
    display: block;

    @media (max-width: 767px) {
      font-size: 3.5rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 4rem;
    }
  }

  .bg-light-gray {
    padding-bottom: 15rem;

    @media (max-width: 767px) {
      padding-bottom: 10rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 10rem;
    }
  }
}

.buttons-container {
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
}

.contact-us-form {
  text-align: left;

  input,
  textarea {
    background: #f7f7f7;
    border: none;
    border-radius: 1rem;
    border-color: #f7f7f7;
    padding: 2rem;
    width: 100%;
    margin-bottom: 2.5rem;
    font-family: 'Inter Variable', sans-serif;
    font-size: 1.5rem;
    box-sizing: border-box;

    @media (max-width: 767px) {
      padding: 1rem 0;
    }
  }

  label {
    color: #848484;
    display: block;
    padding-bottom: 0.5rem;
    font-family: 'Inter Variable', sans-serif;
    font-size: 1.8rem;

    @media (max-width: 767px) {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .btn {
    margin-top: 1rem;
    background-color: #0865de;
    border-color: #0865de;
  }

  .btn.success {
    background: transparent;
    color: #17cb00;
    border-color: #17cb00;
    width: 100%;
  }
}

.wrapper.full-rounded-section {
  margin: 0 5vw;
  padding: 8rem 8vw;

  @media (min-width: 1441px) {
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    padding: 6rem 6vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 5rem 8vw;
  }

  h2 {
    margin: 0 auto;
    margin-bottom: 6rem;
    max-width: 1000px;
    font-family: 'Georgia', sans-serif;
    font-size: 4rem;
    font-weight: 400;
    line-height: 4.6rem;

    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 2.2rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 auto 6rem;
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  h3 {
    text-align: left;
    font-family: 'Inter Variable', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 2rem;
    }
  }

  .btn {
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: 3rem;
      padding: 2rem;
    }
  }
}
