section.image-with-text {
  margin: 0 auto;
  background-color: #f7f7f7;

  .wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    min-height: 75vh;
    padding: 0;

    @media (max-width: 991px) {
      grid-template-columns: 100%;
    }
  }

  .banner__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 5vw;

    h1 {
      font-size: 6rem;
      font-weight: 700;
      font-family: 'Georgia', sans-serif;
      line-height: 6.8rem;
      margin-block-end: 0;

      @media (max-width: 767px) {
        margin-top: 5rem;
        font-size: 3.5rem;
        line-height: 4rem;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 5rem;
        line-height: 5.5rem;
        padding: 0 8rem;
      }
    }

    p {
      font-size: 2.5rem;
      font-family: 'Inter Variable', sans-serif;
      color: #0865de;
      line-height: 3rem;

      @media (max-width: 767px) {
        font-size: 1.6rem;
        line-height: 1.9rem;
        margin: 1.5rem 2rem;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding: 0 4rem;
      }
    }

    @media (max-width: 991px) {
      grid-row: 2;
      text-align: center;
    }
  }

  .banner__image {
    @media (max-width: 991px) {
      grid-row: 1;
      min-height: 400px;
      background-size: cover;
      background-position: center;
    }
  }

  .arrow-container {
    @media (max-width: 767px) {
      padding: 5rem 0 6rem 0;
    }
  }
}
