footer {
  background: #262626;
  color: white;

  .wrapper {
    min-height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 5rem 7vw;
      align-items: flex-start;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      min-height: 12vh;
    }
  }

  span {
    @media (max-width: 767px) {
      font-size: 1.5rem;
      width: 85%;
    }
  }

  a {
    color: white;
  }

  #copyright {
    color: #848484;

    @media (max-width: 767px) {
      padding-top: 3rem;
      font-size: 1rem;
    }
  }
}
