.method-description-card {
  background-color: $method-description-bg-color;
  border-radius: 1rem;
  padding: 7.5rem 3.4rem 8.6rem 3.4rem;
  text-align: center;

  @media (max-width: 767px) {
    margin: 0 2rem;
    padding: 5rem 2rem 7rem 2rem;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 4rem 2rem 3rem;
  }

  @media (min-width: 992px) and (max-width: 1150px) {
    padding: 7.5rem 1vw 8.6rem;
  }

  @media (min-width: 1151px) and (max-width: 1439px) {
    padding: 7.5rem 3vw 8.6rem;
  }

  .method-description-card__img {
    min-height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .method-description-card__title {
    display: block;
    font-size: 3.5rem;
    font-weight: 700;
    padding: 4rem 0 2rem;
    max-width: 80%;
    margin: 0 auto;

    @media (max-width: 767px) {
      font-size: 2.2rem;
      line-height: 2.6rem;
      padding: 0 3rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 2rem 0 0;
    }

    @media (min-width: 992px) and (max-width: 1439px) {
      max-width: 100%;
      font-size: 3.3rem;
    }
  }

  .method-description-card__description {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-family: 'Inter Variable', sans-serif;
    color: #848484;

    @media (max-width: 767px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 80%;
      margin: 2rem auto;
    }
  }
}
