@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;500;600;700;900&display=swap');

@font-face {
  font-family: 'Inter Variable';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Variable'), url('../assets/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Italian Plate No1 Mono';
  font-style: normal;
  font-weight: normal;
  src: local('Italian Plate No1 Mono'), url('../assets/fonts/Italian\ Plate\ No1\ Mono.ttf') format('truetype');
}
