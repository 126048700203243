#cookie-banner {
  .toast {
    position: fixed;
    bottom: 3%;
    right: 2%;
    z-index: 10000;
    background-color: #ffce00;
    border-radius: 10px;
    width: 350px;
    max-width: none;
    height: 90px;

    @media (max-width: 991px) {
      right: 5%;
      bottom: 3%;
    }

    @media (max-width: 767px) {
      bottom: 3%;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 80vw;
      height: auto;
    }

    .toast-body {
      padding: 0;
    }

    .row {
      margin: 0;
    }

    p {
      font-family: 'roboto', sans-serif;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 400;
      text-align: left;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    a {
      text-decoration: underline;
      color: inherit;
    }

    strong {
      font-family: 'roboto', sans-serif;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 700;
      color: black;
    }

    #cookie-icon-column {
      padding-top: 1.5rem;
      padding-right: 0;
      justify-content: center;
      display: flex;

      @media (max-width: 767px) {
        padding-top: 1.5rem;
        padding-left: 1rem;
        align-items: unset;
      }
    }

    #text-column {
      padding-top: 1.5rem;
      padding-right: 2rem;

      @media (max-width: 767px) {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    #cross-icon-column {
      padding-top: 1.5rem;

      #cross-icon {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
  }
}
