section#isa-calculator {
  color: white;
  font-family: 'Inter Variable', sans-serif;

  .rounded-section {
    padding-top: 13rem;
    padding-bottom: 15rem;

    @media (max-width: 767px) {
      padding-bottom: 10rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 10rem;
      padding-bottom: 12rem;
    }
  }

  #isa-calculator__title {
    font-size: 5rem;
    font-family: 'Georgia', sans-serif;
    font-weight: 700;
    line-height: 6rem;
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
    padding-bottom: 3rem;

    @media (max-width: 767px) {
      font-size: 3rem;
      text-align: left;
      line-height: 3.5rem;
      margin: 0;
      max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 4rem;
      line-height: 5rem;
      max-width: 80%;
    }
  }

  #isa-calculator__description {
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    padding-bottom: 8rem;

    @media (max-width: 767px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: left;
      margin: 0;
      max-width: 100%;
      padding-bottom: 6rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 90%;
      padding-bottom: 6rem;
    }
  }

  #calculator-wrapper {
    p {
      display: inline-block;
      font-size: 1.8rem;
      font-weight: bold;

      &#currency-title {
        width: 26%;

        @media (max-width: 991px) {
          width: 25%;
        }
      }

      @media (max-width: 767px) {
        font-size: 1.4rem;
      }
    }
  }

  input {
    width: 50%;
    box-sizing: border-box;
    padding: 2rem;
    margin-bottom: 2rem;
    font-family: 'Italian Plate No1 Mono', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;

    @media (max-width: 991px) {
      width: 75%;
      font-size: 1.7rem;
      padding: 1.2rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 1.2rem;
      font-size: 1.7rem;
    }
  }

  table {
    width: 100%;
    background: #0953b4;
    border-collapse: collapse;

    th,
    td {
      padding: 2rem 0;
      font-size: 2.5rem;
      border: 1px solid white;
      width: 25%;
      text-align: center;
      font-family: 'Italian Plate No1 Mono', sans-serif;

      @media (max-width: 767px) {
        font-size: 1.6rem;
        width: 50%;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 2rem;
        padding: 1.2rem;
      }
    }

    thead th {
      font-size: 2.5rem;
      font-weight: bold;
      font-family: "Inter Variable", sans-serif;

      @media (max-width: 767px) {
        font-size: 1.6rem;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 2rem;
      }
    }

    margin-bottom: 2rem;
  }

  .maximun-payment {
    font-family: 'Italian Plate No1 Mono', sans-serif;
    text-align: right;
    display: block;
    margin-top: 1rem;
    font-size: 2rem;

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 1.7rem;
    }
  }

  #total-payment-wrapper {
    width: 50%;
    box-sizing: border-box;
    margin-left: 50%;
    background-color: white;
    padding: 2rem 6rem;
    text-align: right;
    color: black;
    font-family: 'Italian Plate No1 Mono', sans-serif;
    font-size: 2.5rem;

    @media (max-width: 991px) {
      width: 100%;
      margin-left: 0;
      font-size: 1.4rem;
      padding: 1rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 1.7rem;
    }
  }
}
