header {
  .wrapper {
    min-height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      min-height: 10vh;
    }
  }

  #logo {
    font-size: 3rem;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0865de;
    border-color: #0865de;
    margin-top: 0;
    width: unset;
    width: 100%;
    padding: 0.6rem 0;
    max-width: 100px;
    text-align: center;
    font-size: 1.8rem;
    font-family: 'Inter Variable', sans-serif;

    @media (max-width: 767px) {
      padding: 0.5rem;
      max-width: 75px;
    }
  }

  svg#language-selector-icon {
    margin-right: 1.5rem;
  }
}

.e4e-logo {
  display: inline;
  font-family: 'Barlow', sans-serif;
  font-weight: 900;

  a {
    color: inherit;
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  span {
    font-weight: 400;
  }
}
