.privacy-policy {
  max-width: 78%;
  color: #848484;
  font-family: 'Inter Variable', sans-serif;
  font-size: 2rem;
  line-height: 3rem;
  white-space: pre-line;
  padding-bottom: 12rem;
  padding-top: 5vh;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  h1 {
    font-family: 'Inter Variable', sans-serif;
    font-weight: bold;
    font-size: 5.6rem;
    line-height: 6.7rem;
    color: black;

    @media (max-width: 767px) {
      font-size: 2.5rem;
      line-height: 3.8rem;
    }
  }

  h2 {
    color: #0865de;
    font-size: 2.5rem;
    line-height: 3.1rem;
    font-weight: 400;
    padding-top: 3rem;
    padding-bottom: 0;

    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  h3 {
    color: black;
    font-size: 2.5rem;
    font-weight: 700;
    padding-top: 3rem;
    padding-bottom: 0;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  p {
    margin-top: 1rem;

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  li {
    text-align: left;

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul.dashed {
    list-style-type: none;
  }

  ul.dashed > li::before {
    content: "- ";
    text-indent: -5px;
  }

  a {
    color: #0865de;
    text-decoration: underline;
    font-weight: bold;
  }
}
